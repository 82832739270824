<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Car Park Rate</v-card-title>
        <v-container fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Systems"
                    label="System"
                    v-model="system.selected"
                    @change="execFilter('system')"
                    :items="system.items"
                    item-text="name"
                    item-value="SystemID"
                    :rules="system.rule"
                    :loading="system.loader"
                    :disabled="system.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Operators"
                    label="Operator"
                    v-model="operator.selected"
                    @change="execFilter('operator')"
                    :items="operator.items"
                    item-text="name"
                    item-value="OperatorID"
                    :rules="operator.rule"
                    :loading="operator.loader"
                    :disabled="operator.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="Select Car Park"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('create_carparkrate')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="goToCreateRateCard"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Car Park Rate
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td>{{props.item.CarParkName}}</td>
                            <td>{{props.item.RateCardID}}</td>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="update(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.RateCardName}}</a>
                                    </template>
                                    <span>{{validateAccess('update_carparkrate') ? "Update" : "View"}} Car Park Rate</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.DefaultRateCard}}</td>
                            <td>{{props.item.EntryGracePeriod}}</td>
                            <td>{{props.item.ExitGracePeriod}}</td>
                            <td>{{props.item.CutOff}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.ActiveStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.ActiveStatus, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <calculate :dialog="ma.modal_calculate" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></calculate>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import calculate from '.././modals/CalculateRateCard';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Car Park Rate',
    title: 'Car Park Rate',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park Rate',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'CP ID', value: 'CarParkID', width: '3%' },
                { text: 'CP Code', value: 'CarParkUserInputCode', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '3%' },
                { text: 'Rate Card ID', value: 'RateCardID', width: '3%' },
                { text: 'Rate Card Name', value: 'RateCardName', width: '5%' },
                { text: 'Is Default', value: 'DefaultRateCard', width: '5%' },
                { text: 'Entry Grace Period', value: 'EntryGracePeriod', width: '3%' },
                { text: 'Exit Grace Period', value: 'ExitGracePeriod', width: '3%' },
                { text: 'Cut Off Time', value: 'CutOff', width: '3%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Status', value: 'ActiveStatus', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],

                modal_calculate: false
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                itemsFull2: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
        }
    },

    components: {alert, alertbox2, calculate},

    async created(){
        await this.getCarParkFull2();
        await this.getSystemFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getAll();
    },

    methods: {
        async getAll(){
            this.items = [];
            
            if (this.carpark.selected){
                try{
                    this.tableloading = true;

                    let response = await this.$store.dispatch("getRateCardByCarParkID", {carparkid: this.carpark.selected});
                    let response2 = await this.$store.dispatch("getRateCard", {});

                    if (response.data.code == 'AP000'){
                        this.$vuetify.goTo(0);

                        let dt = response.data.record;
                        let dt2 = response2.data.record;
                        let recordCount = response.data.count;

                        for(var i=0; i<dt.length; i++){
                            dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                            let carparkitem = this.carpark.itemsFull.find(item => item.CarParkID == dt[i].CarParkID);
                            dt[i].CarParkUserInputCode = carparkitem.CarParkUserInputCode == '' ? 'NA' : carparkitem.CarParkUserInputCode;
                            dt[i].CarParkName = carparkitem.CarParkName;

                            let ratecardmore = dt2.find(item => item.RateCardID == dt[i].RateCardID);
                            console.log('ratecardmore', ratecardmore);
                            if (ratecardmore != undefined){
                                dt[i].RateCardName = ratecardmore.RateCardName;
                                dt[i].CutOff = moment(ratecardmore.CutOff,'hh:mm a').format('hh:mm a');
                                //dt[i].CutOff = moment.utc(ratecardmore.CutOff*3600*1000).format('hh:mm A');

                                let entrygrace = ratecardmore.EntryGracePeriod.toFixed(2).toString().split('.');
                                let graceentryhour = entrygrace[0] == '0' ? '' : `${entrygrace[0]} hours `;
                                let graceentryminutes = entrygrace[1] == undefined ? '' : `${entrygrace[1]} minutes`;
                                dt[i].EntryGracePeriod = `${graceentryhour}${graceentryminutes}`;

                                let exitgrace = ratecardmore.ExitGracePeriod.toFixed(2).toString().split('.');
                                let graceexithour = exitgrace[0] == '0' ? '' : `${exitgrace[0]} hours `;
                                let graceexitminutes = exitgrace[1] == undefined ? '' : `${exitgrace[1]} minutes`;
                                dt[i].ExitGracePeriod = `${graceexithour}${graceexitminutes}`;

                                dt[i].DefaultRateCard = dt[i].DefaultRateCard == 1 ? "Yes" : "No";
                            }
                            
                            else
                            dt[i].RateCardName = "None";

                            dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                            dt[i].UpdatedDateTime = moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                            //replace(/\s/g,'') remove any literal whitespace
                        }

                        this.items = dt;
                        this.pages = Math.ceil(recordCount / 100);
                        this.tableloading = false;

                        if (this.$route.query.created || this.$route.query.updated){
                            this.$router.replace({'query': null});
                        }
                    }
                }

                catch(err){
                    console.log(err);
                }
            }
        },

        async getSystemFull(){
            let list = [];
            this.system.items = [];
            try{
                this.system.loader = true;
                this.system.disable = true;
                let response = await this.$store.dispatch("getAllSystem2", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    //this.system.items.push({name: "All Systems", SystemID: ""});
                    //this.system.itemsFull.push({name: "All Systems", SystemID: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].SystemID} - ${dt[i].SystemName}`;
                        this.system.items.push({name: dt[i].name, SystemID: dt[i].SystemID});
                        this.system.itemsFull.push({name: dt[i].name, SystemID: dt[i].SystemID});
                    }
                }

                //this.system.itemsFull = dt;

                if (this.$route.query.created == true || this.$route.query.updated == true
                        || this.$route.query.created == "true" || this.$route.query.updated == "true"
                        || this.$route.query.deleted == "true" || this.$route.query.deleted == "true"){

                        let op = this.carpark.itemsFull2.find(item => item.CarParkID == this.$route.query.carpark);
                        this.system.selected = op.SystemID;
                }

                else
                    this.system.selected = 2;

                this.system.loader = false;
                this.system.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (this.system.selected == dt[i].SystemID){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }


                if (this.$route.query.created == true || this.$route.query.updated == true
                        || this.$route.query.created == "true" || this.$route.query.updated == "true"
                        || this.$route.query.deleted == "true" || this.$route.query.deleted == "true"){

                        let op = this.carpark.itemsFull2.find(item => item.CarParkID == this.$route.query.carpark);
                        this.operator.selected = op.OperatorID;
                }

                else
                    this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull2(){
            let response = await this.$store.dispatch("getAllCarpark2", {});
            let dt = response.data.record;

            if (response.data.code == 'AP000'){
                for(var i=0; i<dt.length; i++){
                    this.carpark.itemsFull2.push(dt[i]);
                }
            }
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;
                if (this.system.selected && !this.operator.selected){
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        //this.carpark.items.push({name: "Select Car Park", CarParkID: ""});
                        //this.carpark.itemsFull.push({name: "Select Car Park", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (this.system.selected == dt[i].SystemID){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }
                    }
                }

                else if (this.system.selected && this.operator.selected){
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        //this.carpark.items.push({name: "Select Car Park", CarParkID: ""});
                        //this.carpark.itemsFull.push({name: "Select Car Park", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (this.operator.selected == dt[i].OperatorID){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }
                    }
                }

                if (this.carpark.items.length > 0){
                    if (this.$route.query.created == true || this.$route.query.updated == true
                        || this.$route.query.created == "true" || this.$route.query.updated == "true"
                        || this.$route.query.deleted == "true" || this.$route.query.deleted == "true"){

                        this.carpark.selected = this.$route.query.carpark;

                        if (this.$route.query.created == "true" || this.$route.query.created == true){
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE('AP000')}: Car Park Rate Created!`;
                        }

                        if (this.$route.query.updated == "true" || this.$route.query.updated == true){
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE('AP000')}: Car Park Rate Updated!`;
                        }

                        if (this.$route.query.deleted == "true" || this.$route.query.deleted == true){
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE('AP000')}: Car Park Rate Deleted!`;
                        }
                    }

                    else
                    this.carpark.selected = this.carpark.items[0].CarParkID;
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'calculate'){
                this.ma.modal_calculate = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update Car Park Rate", params: {id: row.RateCardID}, query: {row: JSON.stringify(row)}});
        },

        /*
        viewCalculate(row){
            this.ma.modal_calculate = true;
            this.ma.modalInfo = row;
            this.ma.allInfo = this.items;
            this.ma.readonly = true;
        },
        */

        goToCreateRateCard(){
            this.$router.push({ name: "Create Car Park Rate", query: {carparkid: this.carpark.selected}});
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_calculate = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                }
            }
        },

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getAll();
          }, 800);
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 1){ return 'Enabled'}
                    else if (val === 0){return 'Disabled'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 1){ return 'green'}
                    else if (val === 0){return 'red'}
                    else {return 'grey'}
            }
        },
    }
}
</script>