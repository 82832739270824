<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="550">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Calculate Rate Card
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
        <v-container fluid>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model.trim="form[2].carparkid"
                        :rules="form[2].rule"
                        label="Car Park ID"
                        required
                        hide-details="auto"
                        autocomplete="off"
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <dpicker :datetime="form[0]" :mode="'datetime'" @date="dateValStartTime" :close="dialog_local" label="Start Time" :width="'58px'"></dpicker>
                    <!--
                    <vc-date-picker v-model="form[0].starttime" mode="dateTime" :masks="{ input: 'DD/MM/YYYY' }" popover>
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                dense
                                outlined
                                :value="inputValue"
                                v-on="inputEvents"
                                :rules="form[0].rule"
                                @click="togglePopover({ placement: 'auto-start' })"
                                label="Start Time"
                                required
                                hide-details="auto"
                                autocomplete="off"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                    -->
                </v-col>
                <v-col cols="12" md="6">
                    <dpicker :datetime="form[1]" :mode="'datetime'" @date="dateValEndTime" :close="dialog_local" label="End Time" :width="'58px'"></dpicker>
                    <!--
                    <vc-date-picker v-model="form[1].endtime" mode="dateTime" :masks="{ input: 'DD/MM/YYYY' }" popover>
                    <template v-slot="{ inputValue, inputEvents, togglePopover }">
                        <v-row dense>
                            <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                dense
                                outlined
                                :value="inputValue"
                                v-on="inputEvents"
                                :rules="form[1].rule"
                                @click="togglePopover({ placement: 'auto-start' })"
                                label="End Time"
                                required
                                hide-details="auto"
                                autocomplete="off"
                            ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    </vc-date-picker>
                    -->
                </v-col>
                <v-overlay
                :absolute="absolute"
                :value="overlay"
                >
                    <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
                </v-overlay>
                <v-col cols="12" style="background: #2EC4B6; color: white">Amount (RM): {{amount.length == '' ? '__' : amount}}<br/>Duration (dd:hh:mm:ss): {{duration.length == '' ? '__' : duration}}</v-col>
                <v-col cols="12"><v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Calculate</v-btn></v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <!-- <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn> -->
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
<v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
</v-row>
</template>
<script>
import alertbox from './Alert';
import * as moment from "moment";
import * as tz from "moment-timezone";
import dpicker from "../DatePickerComponent";
import {ERR} from '../../assets/js/errhandle.js';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {starttime: new Date(""), rule: [] },
                {endtime: new Date(""), rule: [] },
                {carparkid: '', rule: [] },
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            amount: '',
            duration: '',
            absolute: true,
            overlay: false,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
        }
    },

    components: {alertbox, dpicker},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
            this.amount = '';
            this.duration = '';
        },
        },
    },

    created(){
        this.$watch(() => this.form[0].starttime, this.onRuleChange);
    },

    methods: {
        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.SystemID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        async calculateRateCard(){
            this.overlay = true;
            Date.prototype.toLocaleISOString = function() {
            const zOffsetMs = this.getTimezoneOffset() * 60 * 1000;
            const localTimeMs = this - zOffsetMs;
            const date = new Date(localTimeMs);
            const utcOffsetHr = this.getTimezoneOffset() / 60;
            const utcOffsetSign = utcOffsetHr <= 0 ? '+' : '-';
            const utcOffsetString = utcOffsetSign + (utcOffsetHr.toString.length == 1 ? `0${utcOffsetHr}` : `${utcOffsetHr}`) + ':00';
            //return date.toISOString().replace('Z', utcOffsetString);
            return date.toISOString();
            };

            let starttime = this.form[0].starttime.toLocaleISOString();
            let endtime = this.form[1].endtime.toLocaleISOString();

           //let starttime = moment(this.form[0].starttime).format('DD-MM-YYYY HH:mm:ss');
           //let endtime = moment(this.form[1].endtime).format('DD-MM-YYYY HH:mm:ss');

            try{
                let response = await this.$store.dispatch("calculateRateCard", {carparkid: this.modalInfo.cpid, ratecardid: this.modalInfo.ratecardid, starttime: starttime, endtime: endtime});

                if (response.data.code === 'AP000'){
                    let amount = response.data.amount;
                    this.amount = amount.toFixed(2);
                    this.duration = response.data.duration == undefined ? "00:00:00:00" : response.data.duration;
                }

                if (response.data.code === '0'){
                    let amount = response.data.amount;
                    this.amount = amount.toFixed(2);
                    this.duration = response.data.duration == undefined ? "00:00:00:00" : response.data.duration;
                }

                if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to calculate. Invalid Rate Card setup!`;
                }

                this.overlay = false;
            }

            catch(err){
                console.log(err);
            }
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
        },

        async beforeCreateForm(flag){            
            this.form[2].rule = [
                v => !!v || 'Car Park ID is required',
            ];

            this.form[0].rule = [
                v => !!v || 'Date is required',
            ];

            this.form[1].rule = [
                v => !!v || 'Date is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    //self.innerModal = true;
                    //self.ma.modalInfo = {id: 1, name: 2};
                    //self.ma.modal_action_type = flag;

                    self.calculateRateCard();
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.form[0].starttime = '';
            this.form[1].endtime = '';
            this.form[2].carparkid = `${this.modalInfo.cpid} - ${this.modalInfo.carparkname}`;
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        dateValStartTime(value){
            this.form[0].starttime = value;
        },

        dateValEndTime(value){
            this.form[1].endtime = value;
        },
    }
}
</script>